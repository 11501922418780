import {mapMutations, mapActions, mapGetters} from "vuex";
import carousel from "@/modules/product-card/components/carousel/index.vue";
import {dynamicHead} from "../../mixins/dynamic-head";

export default {
    name: "partners",
    components: {carousel},
    mixins: [dynamicHead],

    data() {
        return {}

    },
    watch: {
        '$route.params.slug': function () {
            window.location.reload()
        },
    },
    mounted() {
    },
    created() {

        let slug = this.$route.params.slug
        this.getContent(slug).then(() => {
            this.setPageItem(this.pageContent)
            return document.title = `${this.pageContent.title}`
        })
        this.setRequestFlag(true);

    },
    computed: {
        ...mapGetters({
            pageContent: 'landings/landingContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getContent: 'landings/GET_LENDING'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),

    }
}
